<template>
    <div>
        <DataGridStaticFileFieldRow :section="section" v-for="(section, index) in sections" v-bind:key="index" @remove="removeSection(index)"></DataGridStaticFileFieldRow>
        <!-- p class="p-4 text-xs text-blue-400">DEBUG: {{ sections }}</p -->
    </div>
</template>

<script>
import DataGridStaticFileFieldRow from '@/Shared/DataGridStaticFileFieldRow'

export default {
    props: ['sections', 'deleted_uploads'],
    components: {
        DataGridStaticFileFieldRow,
    },
    data: function() {
        return {
        }
    },
    methods: {
        removeSection: function(index) {
            this.deleted_uploads.push({
                id: this.sections[index].id,
            });

            this.sections.splice(index, 1);
        },
    }
};
</script>