<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('results.index')">Results</inertia-link>
      <span class="text-indigo-400 font-medium">/</span> Create
    </h1>
    <div class="bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="store" autocomplete="off">
        <div class="p-8">
          <form-row label="Title" :isRequired=true><text-input-required v-model="form.title" :error="form.errors.title"/></form-row>
          <form-row label="Narrative"><textarea-input v-model="form.narrative" :error="form.errors.narrative" /></form-row>

          <form-row label="Status">
            <multiselect  v-model="form.marking" 
                          track-by="id" 
                          label="tracking"
                          :multiple="false" 
                          :close-on-select="true" 
                          :options="transitions">
            </multiselect>
          </form-row>

          <form-row label="Indicator">
            <multiselect  v-model="form.indicator_id" 
                          track-by="id" 
                          label="short_version"
                          :multiple="false" 
                          :close-on-select="true" 
                          :options="indicators">
            </multiselect>
          </form-row>

          <form-row label="Result Areas">
            <multiselect  v-model="form.resultarea_ids" 
                          track-by="id" 
                          label="short_version"
                          :multiple="true" 
                          :close-on-select="true" 
                          :options="resultareas">
            </multiselect>
          </form-row>

          <form-row label="Country(ies)">
            <multiselect  v-model="form.country_ids" 
                              track-by="country_id" 
                              label="long_name"
                              :multiple="true" 
                              :close-on-select="true" 
                              :options="countries">
                </multiselect>
          </form-row>

          <form-row label="Primary MEL Contact:">
            <multiselect  v-model="form.pmcontact_id" 
                          track-by="contact_id" 
                          label="full_name"
                          :multiple="false" 
                          :close-on-select="true" 
                          :options="pmcontacts">
            </multiselect>
          </form-row>

          <!-- <form-row label="Gender">
            <ToggleInput class="pb-4 mt-4" :toggle="form.gender"></ToggleInput>
          </form-row> -->

          <form-row label="No. of Females Trained" v-show="showTrainingFields">
            <text-input type="number" 
                v-model.number="form.no_of_females_trained" 
                :error="form.errors.no_of_females_trained" 
                @input="updateTotalTrained" />
          </form-row>

          <form-row label="No. of Males Trained" v-show="showTrainingFields">
            <text-input type="number" 
                          v-model.number="form.no_of_males_trained" 
                          :error="form.errors.no_of_males_trained" 
                          @input="updateTotalTrained" />
          </form-row>

          <form-row label="No. of Unknown Gender Trained" v-show="showTrainingFields">
            <text-input type="number"                         
                          v-model.number="form.no_of_unknown_gender_trained" 
                          :error="form.errors.no_of_unknown_gender_trained"
                          @input="updateTotalTrained" />
          </form-row>

          <form-row label="Total No. Trained" v-show="showTrainingFields">
            <span class="text-green-500">{{ form.total_no_trained }}</span>
            <input type="hidden" v-model.number="form.total_no_trained" />
          </form-row>

          <form-row label="Year Result Met">
            <multiselect  v-model="form.year_result_met" 
                              :multiple="true" 
                              :close-on-select="true" 
                              :options="years">
                </multiselect>
          </form-row>

          <form-row label="Reporting Period">
              <multiselect  v-model="form.reporting_period" 
                            :multiple="false" 
                            :close-on-select="true" 
                            :options="reporting_periods">
              </multiselect>
          </form-row>

          <form-row label="Year Result Reported">
            <multiselect  v-model="form.year_result_reported" 
                              :multiple="true" 
                              :close-on-select="true" 
                              :options="years">
                </multiselect>
          </form-row>

          <form-row label="Files">
            <DataGridFileField :sections="form.new_uploads" :file_set_title="file_set_title" :add_button_label="add_button_label"></DataGridFileField>
          </form-row>

          <form-row label="Notes">
            <DataGridNoteFileField 
                  :sections="form.notes" 
                  :note_types="note_types"
                  :deleted_notes="form.deleted_notes">
            </DataGridNoteFileField>
          </form-row>

          <form-row label="Associated Activities">
            <multiselect  v-model="form.activity_ids" 
                              track-by="activity_id" 
                              label="activity_code"
                              :multiple="true" 
                              :close-on-select="true" 
                              :options="activities"
                              @input="updateActivityRelatedFields">
            </multiselect>
          </form-row>
          <form-row v-if="form.activity_ids.length > 0">
            <ul class="w-full list-disc list-inside grid grid-cols-4 gap-4">
              <li class="pb-2" v-for="activity in form.activity_ids" :key="activity.activity_id">
                <a class="text-indigo-400" :href="`${ activity_url_prefix }${ activity.activity_id }`" target='_blank'>{{ activity.activity_code }}</a>
              </li>
            </ul>
          </form-row>

          <form-row label="Contributing Benchmarks">
            <multiselect  v-model="form.benchmark_ids" 
                          track-by="uuid" 
                          label="title"
                          :multiple="true" 
                          :close-on-select="true" 
                          :options="benchmarks">
            </multiselect>
          </form-row>
          <form-row v-if="form.benchmark_ids.length > 0">
            <ul class="w-full list-disc list-inside">
              <li class="pb-4" v-for="benchmark in form.benchmark_ids" :key="benchmark.uuid">
                <a class="text-indigo-400" :href="`${ benchmark_url_prefix }${ benchmark.uuid }`" target='_blank'>{{ benchmark.title }}</a>
              </li>
            </ul>
          </form-row>

          <form-row label="Contributing Deliverables">
            <multiselect  v-model="form.deliverable_ids" 
                          track-by="uuid" 
                          label="title"
                          :multiple="true" 
                          :close-on-select="true" 
                          :options="deliverables">
            </multiselect>
          </form-row>
          <form-row v-if="form.deliverable_ids.length > 0">
            <ul class="w-full list-disc list-inside">
              <li class="pb-4" v-for="deliverable in form.deliverable_ids" :key="deliverable.uuid">
                <a class="text-indigo-400" :href="`${ deliverable_url_prefix }${ deliverable.uuid }`" target='_blank'>{{ deliverable.title }}</a>
              </li>
            </ul>
          </form-row>

          <form-row label="Stakeholders">
            <multiselect  v-model="form.activitystakeholder_ids" 
                          track-by="uuid" 
                          label="long_name"
                          :multiple="true" 
                          :close-on-select="true" 
                          :options="activitystakeholders">
            </multiselect>
          </form-row>
          <form-row v-if="form.activitystakeholder_ids.length > 0">
            <ul class="w-full list-disc list-inside">
              <li class="pb-4" v-for="activitystakeholder in form.activitystakeholder_ids" :key="activitystakeholder.uuid">
                <a class="text-indigo-400" :href="`${ activitystakeholder_url_prefix }${ activitystakeholder.uuid }`" target='_blank'>{{ activitystakeholder.long_name }}</a>
              </li>
            </ul>
          </form-row>

          <form-row label="Needs and Use Statement">
            <multiselect  v-model="form.needsuse_ids" 
                              track-by="uuid" 
                              label="name_of_analysis"
                              :multiple="true" 
                              :close-on-select="true" 
                              :options="needsuses">
                </multiselect>
              </form-row>
            <form-row v-if="form.needsuse_ids.length > 0">
              <ul class="mb-8 3/4 list-disc list-inside">
                <li class="pb-4" v-for="needsuse in form.needsuse_ids" :key="needsuse.uuid">
                  <a class="text-indigo-400" :href="`${ needsuse_url_prefix }${ needsuse.uuid }`" target='_blank'>{{ needsuse.name_of_analysis }}</a>
                </li>
              </ul>
          </form-row>
        </div>
        <div class="px-8 py-4 bg-gray-50 border-t border-gray-100 flex justify-end items-center">
          <loading-button :loading="form.processing" class="btn-indigo" type="submit">Create Result</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextInputRequired from '@/Shared/TextInputRequired'
import TextareaInput from '@/Shared/TextareaInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'

import DataGridFileField from '@/Shared/DataGridFileField'
import DataGridNoteFileField from '@/Shared/DataGridNoteFileField'
import ToggleInput from '@/Shared/ToggleInput'
import FormRow from '@/Shared/FormRow'

import Multiselect from 'vue-multiselect'

import axios from 'axios'

export default {
  metaInfo: { title: 'Create Result' },
  components: {
    LoadingButton,
    SelectInput,
    TextInput,
    TextInputRequired,
    TextareaInput,
    DataGridFileField,
    DataGridNoteFileField,
    Multiselect,
    FormRow,
    ToggleInput,
  },
  layout: Layout,
  computed: {
    showTrainingFields: function () {
      if (this.form.indicator_id)
        return this.form.indicator_id.short_version.startsWith('A6')
      else
        return false
    }
  },
  props: {
    transitions: Array,
    indicators: Array,
    resultareas: Array,
    countries: Array,
    years: Array,
    reporting_periods: Array,
    note_types: Array,
    activities: Array,
    pmcontacts: Array,
    benchmarks: Array,
    deliverables: Array,
    activitystakeholders: Array,
    needsuses: Array,
    url: String,
    activity_url_prefix: String,
    benchmark_url_prefix: String,
    deliverable_url_prefix: String,
    activitystakeholder_url_prefix: String,
    needsuse_url_prefix: String,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        title: null,
        narrative: null,
        transition: null,
        marking: null,
        indicator_id: null,
        resultarea_ids: null,
        country_ids: null,
        pmcontact_id: null,
        gender: {
          label: 'Gender',
          value: false
        },
        no_of_females_trained: 0,
        no_of_males_trained: 0,
        no_of_unknown_gender_trained: 0,
        total_no_trained: 0,
        year_result_met: null,
        reporting_period: null,
        year_result_reported: null,
        new_uploads: [{
          value: ''
        }],
        benchmark_ids: [],
        deliverable_ids: [],
        activitystakeholder_ids: [],
        needsuse_ids: [],
        notes: [],
        deleted_notes: [],
        activity_ids: [],
      }),
      file_set_title: '',
      add_button_label: "Add New File",
    }
  },
  methods: {
    store() {
      this.form.post(this.route('results.store'))
    },
    updateTotalTrained: function() {
        this.form.total_no_trained = parseInt(this.form.no_of_females_trained) + parseInt(this.form.no_of_males_trained) + parseInt(this.form.no_of_unknown_gender_trained)
    },
    updateActivityRelatedFields() {
      let params = { params: JSON.stringify( this.form.activity_ids.map( a => a.activity_id ) ) };

      axios.get(this.url + '/getactivityrelatedvalues/', params)
        .then(response => {
          this.benchmarks = response.data.benchmark_ids;
          this.deliverables = response.data.deliverable_ids;
          this.activitystakeholders = response.data.activitystakeholder_ids;
          this.needsuses = response.data.needsuse_ids;
        })
        .catch(errors => {
          console.log(errors);
        });
    },
  },
}
</script>
