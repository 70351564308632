<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('resultareas')">Result Areas</inertia-link>
      <span class="text-indigo-400 font-medium">/</span> Create
    </h1>
    <div class="bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="store">
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <text-input-required v-model="form.short_version" :error="form.errors.short_version" class="pr-6 pb-8 w-full font-medium" label="Short Version" />
          <textarea-input v-model="form.long_version" :error="form.errors.long_version" class="pr-6 pb-8 w-full font-medium" label="Long Version" />
        </div>
        <div class="px-8 py-4 bg-gray-50 border-t border-gray-100 flex justify-end items-center">
          <loading-button :loading="form.processing" class="btn-indigo" type="submit">Create Result Area</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextInputRequired from '@/Shared/TextInputRequired'
import TextareaInput from '@/Shared/TextareaInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'

export default {
  metaInfo: { title: 'Create Result Area' },
  components: {
    LoadingButton,
    SelectInput,
    TextInput,
    TextInputRequired,
    TextareaInput,
  },
  layout: Layout,
  props: {
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        short_version: null,
        long_version: null,
      }),
    }
  },
  methods: {
    store() {
      this.form.post(this.route('resultareas.store'))
    },
  },
}
</script>
