<template>
    <div>
        <div class="mb-4 grid grid-flow-col grid-cols-2 gap-2">
            <div class="flex border-b-2 border-indigo-200 rounded-md shadow-sm p-2">
                <a v-model="section" :href="route('fileupload.get', section.id)" class="flex-initial"><icon name="download" class="flex-initial w-4 h-4 mr-4" /></a>
                {{ section.original_file_name }}
            </div>
            <div>
                <button type="button" class="btn-indigo ml-auto text-sm" v-on:click="$emit('remove')">
                    <icon name="close" class="w-4 h-4 fill-white" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/Shared/Icon'

export default {
    props: ['section'],
    components: {
        Icon,
    },
    data: function() {
        return {
        }
    },
};
</script>