<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">API Data</h1>
    <div class="mb-6 flex justify-between items-center">
      <search v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
      </search>
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto" style="min-height:300px;">
      <div>
        <div class="text-left font-bold grid grid-cols-12 gap-2 text-xs">
          <div class="px-2 pt-4 pb-2 cursor-pointer col-span-2" @click="sort('module')">Module<sort-icon fieldName="module" :sortBy="form.sort_by" :sortDirection="form.sort_direction"></sort-icon></div>          
          <div class="px-2 pt-4 pb-2 cursor-pointer col-span-4 ">Params</div>
          <div class="px-2 pt-4 pb-2 cursor-pointer col-span-2" @click="sort('status')">Status<sort-icon fieldName="status" :sortBy="form.sort_by" :sortDirection="form.sort_direction"></sort-icon></div>
          <div class="px-2 pt-4 pb-2 cursor-pointer col-span-2" @click="sort('created_at')">Date Time<sort-icon fieldName="created_at" :sortBy="form.sort_by" :sortDirection="form.sort_direction"></sort-icon></div>       
        </div>
        <div class="text-left font-normal grid grid-cols-12 gap-2 text-xs">
          <multiselect class="px-2 col-span-2 hidden lg:block font-normal text-sm" v-model="form.module_filter" 
                            :multiple="true" 
                            :close-on-select="true" 
                            :max-height="200"
                            placeholder=""
                            :searchable="false"
                            selectedLabel=""
                            selectLabel=""
                            deselectLabel="Deselect"
                            :options="module_filters">
                            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length">{{ values.length }} filters</span></template>
          </multiselect>
          <text-input class="px-3 col-span-4 pb-4 hidden lg:block" v-model="form.params_filter" />
          <multiselect class="px-2 hidden col-span-2 lg:block font-normal text-sm" v-model="form.status_filter" 
                            :multiple="true" 
                            :close-on-select="true" 
                            placeholder=""
                            :searchable="false"
                            selectedLabel=""
                            selectLabel=""
                            deselectLabel="Deselect"
                            :options="status_filters">
                            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length">{{ values.length }} filters</span></template>
          </multiselect>
          <text-input class="px-3 col-span-2 pb-4 hidden lg:block" v-model="form.created_at_filter" />
        </div>
        <div v-for="result in results.data" :key="result.id" class="border-t grid grid-cols-12 gap-2 hover:bg-yellow-100 focus-within:bg-gray-100 text-xs">  
          <div class="px-2 col-span-2 py-4">
            <span tabindex="-1">                
                {{ result.module }}
            </span>
          </div>
          <div class="px-2 col-span-4 py-4">
            <div tabindex="-1" v-for="(param, idx) in result.params" v-bind:key="idx">
              {{ idx + ": " + param }}
            </div>
          </div>
          <div class="px-2 col-span-2 py-4">
            <span class="items-center" tabindex="-1">                
                {{ result.code }}: {{  result.message }}
            </span>
          </div>
          <div class="px-2 col-span-2 py-4 justify-self-center">
            <span class="items-center" tabindex="-1">                
                {{ result.created_at }}
            </span>
          </div>
        </div>
        <div v-if="results.data.length === 0">
          <div class="border-t px-4 py-4 item-center" colspan="4">No results found.</div>
        </div>
      </div>
    </div>
    <div class="justify-between lg:flex" v-if="results.total > 0">
      <div class="mt-6 mr-5 lg:shrink-0 ">
        <div class="mb-1 px-4 py-2 text-sm leading-4 bg-white border rounded"> Showing {{ results.from + ' to ' + results.to + ' of ' + results.total }} entries </div>
        <div>
          <select-input class="text-sm lg:shadow-inner rounded-lg" v-model="form.page_len" label="">
              <option :value="10">10 rows per page</option>
              <option :value="20">20 rows per page</option>
              <option :value="50">50 rows per page</option>
              <option :value="100">100 rows per page</option>
          </select-input>
        </div>
      </div>
      <pagination class="mt-6" :links="results.links" />
    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import Search from '@/Shared/Search'
import SearchFilter from '@/Shared/SearchFilter'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import SortIcon from '@/Shared/SortIcon'
import Multiselect from 'vue-multiselect'

export default {
  metaInfo: { title: 'API Monitoring Tools' },
  components: {
    Icon,
    Pagination,
    Search,
    SearchFilter,
    SelectInput,
    SortIcon,
    Multiselect,
    TextInput,
  },
  layout: Layout,
  props: {
    filters: Object,
    page_len: Number,
    results: Object,
    module_filters: Array,
    status_filters: Array,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        module_filter: this.filters.module_filter,
        status_filter: this.filters.status_filter,
        params_filter: this.filters.params_filter,
        created_at_filter: this.filters.created_at_filter,
        sort_by: this.filters.sort_by,
        sort_direction: this.filters.sort_direction,
        page_len: this.page_len,
      },
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function() {
        this.$inertia.get(this.route('apimonitor.index'), pickBy(this.form), { preserveState: true })
      }, 800),
    },
  },
  methods: {
    reset() {
      // Preserve certain form values
      const page_len_temp = this.form.page_len

      // Clear form values
      this.form = mapValues(this.form, () => null)

      // Reset sorting
      this.form.sort_by = 'module'
      this.form.sort_direction = 'ASC'

      // Restore preserved form values
      this.form.page_len = page_len_temp
    },
    sort(column) {
      this.form.sort_by = column
      this.form.sort_direction = this.form.sort_direction == 'ASC' ? 'DESC' : 'ASC'
    },
    stringLimiter(text) {
      if(typeof text === "string") {
        return text.length > 120 ? text.slice(0, 120) + '...' : text;
      } else {
        return text;
      }
    },
    stringConcater(text, idx) {      
      return idx > 0 ? '; ' + text.trim() : text.trim();      
    }
  }
}
</script>

