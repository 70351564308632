<template>
    <div>
        <p v-if="file_set_title" class="mb-4 ml-2">{{ file_set_title }}</p>
        <DataGridFileFieldRow :section="section" v-for="(section, index) in sections" v-bind:key="index" @remove="removeSection(index)"></DataGridFileFieldRow>
        <button class="btn-indigo ml-auto rounded-full text-sm" type="button" v-on:click="addComponent()">{{ add_button_label }}</button>
        <!-- p class="p-4 text-xs text-blue-400">DEBUG: {{ sections }}</p -->
    </div>
</template>

<script>
import DataGridFileFieldRow from '@/Shared/DataGridFileFieldRow'

export default {
    props: ['sections', 'file_set_title', 'add_button_label'],
    components: {
        DataGridFileFieldRow,
    },
    data: function() {
        return {
        }
    },
    methods: {
        removeSection: function(index) {
            this.sections.splice(index, 1)
        },
        addComponent: function() {
            this.sections.push({
                value: ''
            });
        }
    }
};
</script>