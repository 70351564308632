<template>
	<layout title="Become User">
		<div class="bg-white rounded-md shadow overflow-hidden">
			<form @submit.prevent="syncmis">			
				<table class="w-full whitespace-nowrap">
					<tr class="text-left font-bold">
						<th class="px-6 pt-6 pb-4 cursor-pointer">Modul</th>
						<th class="px-6 pt-6 pb-4 cursor-pointer">File</th>
						<th class="px-6 pt-6 pb-4 cursor-pointer">Last Modified</th>
						<th class="px-6 pt-6 pb-4 cursor-pointer">Status</th>
					</tr>
					<tr 
						v-for="(file, idxFile) in files" :class="idxFile % 2 == 0 ? 'bg-sky-50' : ''" :key="idxFile"
						class="hover:bg-yellow-100 focus-within:bg-gray-100"
						>
						<td class="border-t px-6 py-4 items-center">{{ file['name'] }}</td>
						<td class="border-t px-6 py-4 items-center">{{ file['file'] }}</td>
						<td class="border-t px-6 py-4 items-center">{{ file['lastModified'] }}</td>
						<td class="border-t px-6 py-4 items-center">{{ file['status'] }}</td>
					</tr>
				</table>


				<div class="px-8 py-4 bg-gray-50 border-t border-gray-100 flex items-center">
					<loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit">Sync</loading-button>
				</div>
			</form>
		</div>
	</layout>
</template>

<script>
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'

export default {
	components: {	
		LoadingButton,
	},
	layout: Layout,
	props: {
		files: Array,
	},
	remember: 'form',
	data() {
		return {
			form: this.$inertia.form({
				_method: 'post',				
			}),
		}
	},
	methods: {
		syncmis() {
			this.form.post(this.route('syncmis.process'))
		},
	},
}
</script>