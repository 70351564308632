<template>
    <div>
        <DataGridNoteFileFieldRow 
            :section="section" 
            :note_types="note_types"
            v-for="(section, index) in sections" 
            v-bind:key="index"
            @remove="removeSection(index)"></DataGridNoteFileFieldRow>
        <button class="btn-indigo ml-auto rounded-full text-sm" type="button" v-on:click="addSection()">Add New Note</button>
        <!-- p class="p-4 text-xs text-blue-400">DEBUG: {{ sections }}</p -->
    </div>
</template>

<script>
import DataGridNoteFileFieldRow from '@/Shared/DataGridNoteFileFieldRow'

export default {
    props: ['sections', 'note_types', 'deleted_notes'],
    components: {
        DataGridNoteFileFieldRow,
    },
    data: function() {
        return {
        }
    },
    methods: {
        removeSection: function(index) {
            this.sections[index].deleted_nfile_uploads.push({
                id: this.sections[index].id,
            });

            this.deleted_notes.push({
                id: this.sections[index].id,
            });

            this.sections.splice(index, 1)
        },
        addSection: function() {
            this.sections.push({
                id: -1,
                note_date: '',
                note_type: '',
                content: '',
                nfileuploads: [],
                new_nfile_uploads: [{value: ''}],
                deleted_nfile_uploads: [],
            });
        }
    }
};
</script>