<template>
    <div>
      <highcharts :options="chartOptions"></highcharts>
    </div>
  </template>
  
  <script>
  import {Chart} from 'highcharts-vue'
  import Highcharts from 'highcharts'
  import exporting from 'highcharts/modules/exporting'
  
  import { EventBus } from "@/event-bus.js";
  
  exporting(Highcharts);
  
  export default {
    metaInfo: { },
    props: ['title', 'label', 'categories', 'series'],
    components: {
      highcharts: Chart,
    },
    data() {
      return {
        chartOptions: {
          chart: {
              type: 'column'
          },
          title: {
              text: this.title,
          },
          xAxis: {
              categories: this.categories,
          },
          yAxis: {
              min: 0,
              title: {
                  text: this.label,
              },
              stackLabels: {
                  enabled: true,
                  style: {
                      fontWeight: 'bold',
                      /*
                      color: ( // theme
                          Highcharts.defaultOptions.title.style &&
                          Highcharts.defaultOptions.title.style.color
                      ) || 'gray'
                      */
                  },
                  formatter: function() {
                    return  this.stack;
                  }
              }
          },
          legend: {
              align: 'right',
              x: -30,
              verticalAlign: 'top',
              y: 25,
              floating: true,
              /*
              backgroundColor:
                  Highcharts.defaultOptions.legend.backgroundColor || 'white',
              */
              borderColor: '#CCC',
              borderWidth: 0,
              shadow: false
          },
          tooltip: {
              headerFormat: '<b>{point.headerToolTip}</b><br/>',
              pointFormat: '<b>{point.headerToolTip}</b><br/>{series.name}: {point.y}<br/>Total: {point.stackTotal}'
          },
          plotOptions: {
              column: {
                  stacking: 'normal',
                  dataLabels: {
                      enabled: true,
                      style: {
                          textOutline: false
                      }
                  },
              },
              series: {
                  cursor: 'pointer',
                  point: {
                      events: {
                          click: function (event) {
                              // Use event bus to avoid page reload
                              // window.open(event.point.url);
                              EventBus.$emit('chartClicked', event.point.url);
                          }
                      }
                  }
              },
          },
          series: this.series,
        }
      }
    },
  }
  </script>
  