<template>
    <div class="p-4 mb-2 shadow-lg">
        <div class="mb-4 grid grid-flow-col grid-cols-3 gap-2">
            <div class="border-b-2 border-indigo-200 rounded-md shadow-sm p-2">{{ section.note_date }}</div>
            <div class="border-b-2 border-indigo-200 rounded-md shadow-sm p-2">{{ section.note_type }}</div>
            <div class="border-b-2 border-indigo-200 rounded-md shadow-sm p-2">{{ section.content }}</div>
        </div>
        <DataGridReadOnlyFileField :sections="section.nfileuploads" :is_note_file="true"></DataGridReadOnlyFileField>
        <div>Created by: {{ section.created_by }} on {{ section.created_at }}</div>
    </div>
</template>

<script>
import DataGridReadOnlyFileField from '@/Shared/DataGridReadOnlyFileField'
import Icon from '@/Shared/Icon'

export default {
    props: ['section'],
    components: {
        DataGridReadOnlyFileField,
        Icon,
    },
    data: function() {
        return {
        }
    },
};
</script>