<template>
    <div class="p-4 mb-4 shadow-lg">
        <div class="mb-6 flex">
            <div class="flex-col w-3/12 mr-3">
                
                <multiselect  
                    class="flex-1" 
                    v-model="section.note_type" 
                    :multiple="false" 
                    placeholder="Choose Note Type"
                    :close-on-select="true" 
                    :options="note_types">
                </multiselect>
                <div class="flex-1 mt-3">
                    <DatePicker       
                        style="width:100%;"
                        class="flex-1"                 
                        aria-label="date picker"
                        valueType="YYYY-MM-DD"
                        v-model="section.note_date">
                    </DatePicker>
                </div>
            </div>
            <textarea-input placeHolder="Your note here!" rows="3" class="w-8/12 mr-3" v-model="section.content"></textarea-input>

            <div class="w-1/12">
                <button type="button" class="btn-red ml-auto text-sm" v-on:click="$emit('remove')">
                    <icon name="trash" class="w-4 h-4 fill-white" />
                </button>
            </div>
        </div>
        <form-row label="Attach Files:">
            <DataGridStaticNoteFileField :sections="section.nfileuploads" :deleted_uploads="section.deleted_nfile_uploads"></DataGridStaticNoteFileField>
            <DataGridFileField :sections="section.new_nfile_uploads" class=" mt-0" :file_set_title="file_set_title" :add_button_label="add_button_label"></DataGridFileField>
        </form-row>
        <div v-if="section.result_id">Created by: {{ section.created_by }} on {{ section.created_at }}</div>
        
    </div>
</template>

<script>
import TextareaInput from '@/Shared/TextareaInput'
import DatePicker from 'vue2-datepicker';
import FileInput from '@/Shared/FileInput'
import FormRow from '@/Shared/FormRow';
import Icon from '@/Shared/Icon'

import DataGridStaticNoteFileField from '@/Shared/DataGridStaticNoteFileField'
import DataGridFileField from '@/Shared/DataGridFileField'

import Multiselect from 'vue-multiselect'

export default {
    props: ['section', 'note_types'],
    components: {
        DatePicker,
        TextareaInput,
        FormRow,
        FileInput,
        Icon,
        DataGridStaticNoteFileField,
        DataGridFileField,
        Multiselect,
    },
    data: function() {
        return {
            file_set_title: '',
            add_button_label: 'Attach New',
        }
    },
};
</script>