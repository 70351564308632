<template>
    <div>
        <DataGridReadOnlyNoteFileFieldRow 
            :section="section" 
            v-for="(section, index) in sections"
            v-bind:key="index"></DataGridReadOnlyNoteFileFieldRow>
    </div>
</template>

<script>
import DataGridReadOnlyNoteFileFieldRow from '@/Shared/DataGridReadOnlyNoteFileFieldRow'

export default {
    props: ['sections'],
    components: {
        DataGridReadOnlyNoteFileFieldRow,
    },
    data: function() {
        return {
        }
    },
    methods: {
    }
};
</script>