<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('contacts')">Contacts</inertia-link>
      <span class="text-indigo-400 font-medium">/</span>
      {{ form.first_name }} {{ form.last_name }}
    </h1>
    <trashed-message v-if="contact.deleted_at" class="mb-6" @restore="restore">
      This contact has been deleted.
    </trashed-message>
    <div class="bg-white rounded-md shadow overflow-hidden max-w-3xl">
      <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
        <p class="pr-6 pb-8 w-full lg:w-1/2">First Name: {{ contact.first_name }}</p>
        <p class="pr-6 pb-8 w-full lg:w-1/2">Last Name: {{ contact.last_name }}</p>
        <p class="pr-6 pb-8 w-full lg:w-1/2">Organization:
          <ul class="list-disc p-8">
            <li v-for="item in organizations.filter( item => item.id == contact.organization_id )"> 
              {{ item.name }} 
            </li>
          </ul>
        </p>
        <p class="pr-6 pb-8 w-full lg:w-1/2">Email: {{ contact.email }}</p>
        <p class="pr-6 pb-8 w-full lg:w-1/2">Phone: {{ contact.phone }}</p>
        <p class="pr-6 pb-8 w-full lg:w-1/2">Address: {{ contact.address }}</p>
        <p class="pr-6 pb-8 w-full lg:w-1/2">City: {{ contact.city }}</p>
        <p class="pr-6 pb-8 w-full lg:w-1/2">Region: {{ contact.region }}</p>
        <p class="pr-6 pb-8 w-full lg:w-1/2">Country: {{ contact.country }}</p>
        <p class="pr-6 pb-8 w-full lg:w-1/2">Postal Code: {{ contact.postal_code }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  metaInfo() {
    return {
      title: `${this.form.first_name} ${this.form.last_name}`,
    }
  },
  components: {
    TrashedMessage,
  },
  layout: Layout,
  props: {
    contact: Object,
    organizations: Array,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        first_name: this.contact.first_name,
        last_name: this.contact.last_name,
        organization_id: this.contact.organization_id,
        email: this.contact.email,
        phone: this.contact.phone,
        address: this.contact.address,
        city: this.contact.city,
        region: this.contact.region,
        country: this.contact.country,
        postal_code: this.contact.postal_code,
      }),
    }
  },
  methods: {
  },
}
</script>
