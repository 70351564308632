<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">Api Monitoring Tools</h1>
    <div class="bg-white rounded-md shadow overflow-x-auto" style="min-height:300px;">
      
      <HighChartStackGroup class="m-8"
          :title="Title"
          :series=series
          :label="Label"
          :categories=xAxis
      ></HighChartStackGroup>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'

import HighChartStackGroup from '@/Shared/HighChartStackGroup'

import { EventBus } from "@/event-bus.js";

export default {
  metaInfo: { title: 'API Monitoring Dashboard' },
  props: {
    series: Array,
    xAxis: Array,

  },
  components: {
    HighChartStackGroup,
  },
  data() {
    return {
    }
  },
  layout: Layout,
  mounted() {
    // Use event bus to avoid page reload
    EventBus.$on('chartClicked', url => {
      this.$inertia.get(url)
    });
  },
}
</script>
