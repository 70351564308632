<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route(urlName+ '.index')">{{ pageName }}</inertia-link>
      <span class="text-indigo-400 font-medium">/</span>
      {{ form.short_version }}
    </h1>
    <trashed-message v-if="varObj.deleted_at" class="mb-6" @restore="restore">
      This {{ pageName.toLowerCase() }} has been deleted.
    </trashed-message>
    <div class="bg-white rounded-md shadow overflow-hidden">
      <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Short Version:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4">{{ varObj.short_version }}</p>
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Long Version:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4">{{ varObj.long_version }}</p>
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Target:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4">{{ varObj.target }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  metaInfo() {
    return {
      title: `${this.pageName}`,
    }
  },
  components: {
    TrashedMessage,
  },
  layout: Layout,
  props: {
    varObj: Object,
    pageName: String,
    urlName: String,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        short_version: this.varObj.short_version,
        long_version: this.varObj.long_version,
      }),
    }
  },
  methods: {
  },
}
</script>
