<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import {Chart} from 'highcharts-vue'

export default {
  metaInfo: { },
  props: ['data'],
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        series: [{
          data: this.data,
        }]
      }
    }
  },
}
</script>
