<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import {Chart} from 'highcharts-vue'
import Highcharts from 'highcharts'
import exporting from 'highcharts/modules/exporting'

import { EventBus } from "@/event-bus.js";

exporting(Highcharts);

export default {
  metaInfo: { },
  props: ['title', 'label', 'data', 'total'],
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: this.title
        },
        subtitle: {
            text: this.total,
            align: 'center',
            verticalAlign: 'middle',
            style: { "fontSize": "22px" },
            y: 50
				},
        tooltip: {
            // pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            headerFormat: '<span></span>',
            pointFormat: '<span>{point.name}</span>: <b>{point.y}</b> <b>({point.percentage:.1f}%)</b><br/>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                size: '65%',
                allowPointSelect: true,
                cursor: 'pointer',
                center: ['50%', '50%'],
                dataLabels: {
                    enabled: true,
                    distance: 15,
                    // format: '<b>{point.name}</b>: {point.y}',
                },
                showInLegend: false,
                events: {
                  click: function (event) {
                      // Use event bus to avoid page reload
                      // window.open(event.point.url);
                      EventBus.$emit('chartClicked', event.point.url);
                  },
                  mouseOver: function() {
                  },
                  mouseOut: function() {
                  }
                }
            },
        },
        series: [{
            name: this.label,
            colorByPoint: true,
            innerSize: '50%',
            data: this.data,
        }]
      }
    }
  },
}
</script>