<template>
    <div class="md:flex mb-6">
        <div class="lg:w-1/4 md:w-1/3">
            <label class="block text-gray-600 md:text-left mb-3 md:mb-0 pr-4" for="my-textfield">
                {{ label }} <sup v-if="isRequired" class="required"></sup>
            </label>
        </div>
        <div class="lg:w-3/4 md:w-2/3">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: String,
        isRequired: {
            default:false,
        }
    },
    components: {        
    },
    methods: {
    },
    mounted() {
    },
    filters: {  	    
    },
};
</script>