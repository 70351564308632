<template>
    <div>
        <DataGridNoteFieldRow  :section="section" v-for="(section, index) in sections" v-bind:key="index" @remove="removeSection(index)"></DataGridNoteFieldRow>
        <button class="btn-indigo ml-auto rounded-full text-sm" type="button" v-on:click="addSection()">Add New Note</button>
        <!-- p class="p-4 text-xs text-blue-400">DEBUG: {{ sections }}</p -->
    </div>
</template>

<script>
import DataGridNoteFieldRow from '@/Shared/DataGridNoteFieldRow'

export default {
    props: ['sections'],
    components: {
        DataGridNoteFieldRow,
    },
    data: function() {
        return {
        }
    },
    methods: {
        removeSection: function(index) {
            this.sections.splice(index, 1)
        },
        addSection: function() {
            this.sections.push({
                note_date: '',
                content: '',
            });
        }
    }
};
</script>