<template>
    <div>
      <highcharts :options="chartOptions"></highcharts>
    </div>
  </template>
  
  <script>
  import {Chart} from 'highcharts-vue'
  import Highcharts from 'highcharts'
  import exporting from 'highcharts/modules/exporting'
  
  import { EventBus } from "@/event-bus.js";
  
    Highcharts.SVGRenderer.prototype.symbols['c-rect9'] = function (x, y, w, h) {
        w += 34;
        return ['M', x - w + 8, y + h / 2, 'L', x + w, y + h / 2];
    };

    Highcharts.SVGRenderer.prototype.symbols['c-rect8'] = function (x, y, w, h) {
        w += 38;
        return ['M', x - w + 8, y + h / 2, 'L', x + w, y + h / 2];
    };

    Highcharts.SVGRenderer.prototype.symbols['c-rect7'] = function (x, y, w, h) {
        w += 46;
        return ['M', x - w + 8, y + h / 2, 'L', x + w, y + h / 2];
    };

    Highcharts.SVGRenderer.prototype.symbols['c-rect6'] = function (x, y, w, h) {
        w += 54;
        return ['M', x - w + 8, y + h / 2, 'L', x + w, y + h / 2];
    };

    Highcharts.SVGRenderer.prototype.symbols['c-rect5'] = function (x, y, w, h) {
        w += 62;
        return ['M', x - w + 8, y + h / 2, 'L', x + w, y + h / 2];
    };

    Highcharts.SVGRenderer.prototype.symbols['c-rect4'] = function (x, y, w, h) {
        w += 82;
        return ['M', x - w + 8, y + h / 2, 'L', x + w, y + h / 2];
    };

    Highcharts.SVGRenderer.prototype.symbols['c-rect2'] = function (x, y, w, h) {
        w += 170;
        return ['M', x - w + 8, y + h / 2, 'L', x + w, y + h / 2];
    };

  exporting(Highcharts);
  
  export default {
    metaInfo: { },
    props: ['title', 'label', 'categories', 'series'],
    components: {
      highcharts: Chart,
    },
    data() {
      return {
        chartOptions: {
          chart: {
              type: 'column'
          },
          title: {
              text: this.title,
          },
          xAxis: {
              categories: this.categories,
          },
          yAxis: {
              min: 0,
              title: {
                  text: this.label,
              },
              stackLabels: {
                  enabled: true,
                  style: {
                      fontWeight: 'bold',
                      /*
                      color: ( // theme
                          Highcharts.defaultOptions.title.style &&
                          Highcharts.defaultOptions.title.style.color
                      ) || 'gray'
                      */
                  }
              }
          },
          legend: {
              align: 'right',
              x: -30,
              verticalAlign: 'top',
              y: 25,
              floating: true,
              /*
              backgroundColor:
                  Highcharts.defaultOptions.legend.backgroundColor || 'white',
              */
              borderColor: '#CCC',
              borderWidth: 0,
              shadow: false
          },
          tooltip: {
              headerFormat: '<b>{point.x}</b><br/>',
              pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
          },
          plotOptions: {
              column: {
                  stacking: 'normal',
                  dataLabels: {
                      enabled: true,
                      style: {
                          textOutline: false
                      }
                  },
              },
              series: {
                  cursor: 'pointer',
                  pointPadding: 0.2,
      groupPadding: 0.1,
      targetOptions: {
        borderWidth: 0,
        height: 8,
        color: 'red',
        width: '100%'
      },
                  point: {
                      events: {
                          click: function (event) {
                              // Use event bus to avoid page reload
                              // window.open(event.point.url);
                              EventBus.$emit('chartClicked', event.point.url);
                          }
                      }
                  },
              },
          },
          series: this.series,
        }
      }
    },
  }
  </script>
  