<template>
  <layout title="Become User">
    <div class="mb-8 flex justify-start max-w-3xl">
      <h1 class="font-bold text-3xl">
        <span class="text-indigo-400 font-medium">{{ form.first_name }} {{ form.last_name }} / </span>
        Become User
      </h1>
    </div>
    <div class="bg-white rounded-md shadow overflow-hidden max-w-3xl">
      <form @submit.prevent="becomeuser">
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <multiselect  v-model="form.id" 
                        track-by="id" 
                        label="full_name"
                        :maxHeight="150"
                        :multiple="false" 
                        :close-on-select="true" 
                        :options="users">
          </multiselect>
          
        </div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <div class="px-8 py-4 bg-gray-50 border-t border-gray-100 flex items-center">
          <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit">Proceed</loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import Multiselect from 'vue-multiselect'

  export default {
    components: {
      TextInput,
      LoadingButton,
      Multiselect,
    },
    layout: Layout,
    props: {
      users: Array,
      user: Object,
    },
    remember: 'form',
    data() {
      return {
        form: this.$inertia.form({
          _method: 'post',
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email,
          id: this.user.id,
        }),
      }
    },
    methods: {
      becomeuser() {
        this.form.post(this.route('users.becomeuser'))
      },
    },
  }
</script>