<template>
    <div class="w-full flex items-center" @click="toggle.value = !toggle.value">
        <div class="font-medium mr-4" v-if="label">{{ toggle.label }}: </div>
        <div class="w-8 h-5 flex items-center rounded-full p-1 duration-300 ease-in-out" :class="{ 'bg-green-400': toggle.value, 'bg-gray-300': !toggle.value }">
            <div class="bg-white w-3 h-3 rounded-full shadow-md transform duration-300 ease-in-out" :class="{ 'translate-x-3': toggle.value }"></div>
        </div>
        <div>
            <input type="hidden" v-model="toggle.value" />
        </div>
    </div>
</template>

<script>
export default {
    props: ['label', 'toggle'],
    data() {
        return {
        }
    },
}
</script>