<template>
    <div>
        <div class="mb-4 grid grid-flow-col grid-cols-2">
            <div class="border-b-2 border-indigo-200 rounded-md shadow-sm p-2 flex">
                <a v-if="is_note_file" v-model="section" :href="route('notefileupload.get', section.id)" class="flex-initial"><icon name="download" class="flex-initial w-4 h-4 mr-4" /></a>
                <a v-else v-model="section" :href="route('fileupload.get', section.id)" class="flex-initial"><icon name="download" class="flex-initial w-4 h-4 mr-4" /></a>
                {{ section.original_file_name }}
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/Shared/Icon'

export default {
    props: ['section', 'is_note_file'],
    components: {
        Icon,
    },
    data: function() {
        return {
        }
    },
};
</script>