<template>
  <span>
    <slot></slot>
    <span class="svg">
      <svg v-if="sortBy == fieldName && sortDirection == 'ASC'" style="color:#555555" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 320 512"><path d="M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"/></svg>
      <svg v-else-if="sortBy == fieldName && sortDirection == 'DESC'" style="color:#555555" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 320 512"><path d="M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z"/></svg>
      <svg v-else style="color:#cccccc" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 320 512"><path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    sortBy: String,
    fieldName: String,
    sortDirection: String,
  },
}
</script>

<style scoped>
.svg {
  display: inline-block;
  margin-left: 2px;
}
</style>