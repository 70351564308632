<template>
	<div>
		<div class="mb-2">
			<inertia-link class="flex items-center group py-3" :href="route('dashboard')" @click="allToggleOff()" >
				<icon name="dashboard" class="w-4 h-4 mr-2" :class="isUrl('dashboard', false) ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'" />
				<div :class="isUrl('dashboard', false) ? 'text-white' : 'text-indigo-300 group-hover:text-white'">Dashboard</div>
			</inertia-link>
		</div>
		<div class="mb-2">
			<inertia-link class="flex items-center group py-3" :href="route('results.index')" @click="allToggleOff()" >
				<icon name="newspaper" class="w-4 h-4 mr-2" :class="isUrl('results') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'" />
				<div :class="isUrl('results') ? 'text-white' : 'text-indigo-300 group-hover:text-white'">Results</div>
			</inertia-link>
		</div>
		<div class="mb-2">
			<inertia-link class="flex items-center group py-3" :href="route('indicators.index')" @click="allToggleOff()" >
				<icon name="store-front" class="w-4 h-4 mr-2" :class="isUrl('indicators') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'" />
				<div :class="isUrl('indicators') ? 'text-white' : 'text-indigo-300 group-hover:text-white'">Indicators</div>
			</inertia-link>
		</div>
		<div class="mb-2">
			<inertia-link class="flex items-center group py-3" :href="route('resultareas.index')" @click="allToggleOff()" >
				<icon name="text-box" class="w-4 h-4 mr-2" :class="isUrl('resultareas') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'" />
				<div :class="isUrl('resultareas') ? 'text-white' : 'text-indigo-300 group-hover:text-white'">Result Areas</div>
			</inertia-link>
		</div>

		<div class="flex items-center group py-3" @click="adminToolsToggle()"  v-if="$page.props.auth.user.perm.settings.edit || $page.props.auth.user.perm.records.is_mis_admin">
			<icon name="location" class="w-4 h-4 mr-2" :class="forAdminTools ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'" />
			<div :class="forAdminTools ? 'text-white' : 'text-indigo-300 group-hover:text-white'">Admin Tools</div>
			<icon :name="forAdminTools ? 'cheveron-up' : 'cheveron-down'" class="w-4 h-4 ml-3" :class="forAdminTools ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'" />
		</div>
		<div class="ml-4" v-show="forAdminTools">
			<inertia-link class="flex items-center group py-3" :href="route('users.becomeuserform', $page.props.auth.user.id)" v-if="$page.props.auth.user.perm.settings.edit || $page.props.auth.user.perm.records.is_mis_admin">
				<icon name="cheveron-right" class="w-4 h-4 mr-2" :class="isUrl('users.becomeuserform', false) ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'" />
				<div :class="isUrl('users.becomeuserform', false) ? 'text-white' : 'text-indigo-300 group-hover:text-white'">Become User</div>
			</inertia-link>
		</div>    
		<div class="ml-4" v-show="forAdminTools">
			<inertia-link class="flex items-center group py-3" :href="route('syncmis')" v-if="$page.props.auth.user.perm.settings.edit || $page.props.auth.user.perm.records.is_mis_admin">
				<icon name="cheveron-right" class="w-4 h-4 mr-2" :class="isUrl('syncmis', false) ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'" />
				<div :class="isUrl('syncmis', false) ? 'text-white' : 'text-indigo-300 group-hover:text-white'">Sync MIS</div>
			</inertia-link>
		</div>


		<div class="mb-2">
			<inertia-link class="flex items-center group py-3" :href="route('users.acl')" v-if="$page.props.auth.user.perm.settings.edit"  @click="allToggleOff()">
				<icon name="user-group" class="w-4 h-4 mr-2" :class="isUrl('users.acl') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'" />
				<div :class="isUrl('users.acl') ? 'text-white' : 'text-indigo-300 group-hover:text-white'">User Console</div>
			</inertia-link>
		</div>
		<div class="mb-2">
			<inertia-link class="flex items-center group py-3" :href="route('system-health')" v-if="$page.props.auth.user.perm.settings.edit"  @click="allToggleOff()">
				<icon name="radar" class="w-4 h-4 mr-2" :class="isUrl('system-health') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'" />
				<div :class="isUrl('system-health') ? 'text-white' : 'text-indigo-300 group-hover:text-white'">System Health</div>
			</inertia-link>
		</div>
		<div class="flex items-center group py-3" @click="apiToggle()"  v-if="$page.props.auth.user.perm.settings.edit">
			<icon name="location" class="w-4 h-4 mr-2" :class="forApi ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'" />
			<div :class="forApi ? 'text-white' : 'text-indigo-300 group-hover:text-white'">API Tools</div>
			<icon :name="forApi ? 'cheveron-up' : 'cheveron-down'" class="w-4 h-4 ml-3" :class="forApi ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'" />
		</div>
		<div class="ml-4" v-show="forApi">
			<inertia-link class="flex items-center group py-3" :href="route('apimonitor.chart')" v-if="$page.props.auth.user.perm.settings.edit">
				<icon name="cheveron-right" class="w-4 h-4 mr-2" :class="isUrl('apimonitor.chart', false) ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'" />
				<div :class="isUrl('apimonitor.chart', false) ? 'text-white' : 'text-indigo-300 group-hover:text-white'">Chart</div>
			</inertia-link>
		</div>    
		<div class="ml-4" v-show="forApi">
			<inertia-link class="flex items-center group py-3" :href="route('apimonitor.index')" v-if="$page.props.auth.user.perm.settings.edit">
				<icon name="cheveron-right" class="w-4 h-4 mr-2" :class="isUrl('apimonitor') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'" />
				<div :class="isUrl('apimonitor') ? 'text-white' : 'text-indigo-300 group-hover:text-white'">API Data</div>
			</inertia-link>
		</div>
		<div class="mb-2">
			<inertia-link class="flex items-center group py-3" :href="route('settings.edit', 1)" v-if="$page.props.auth.user.perm.settings.edit"  @click="allToggleOff()">
				<icon name="cog" class="w-4 h-4 mr-2" :class="isUrl('settings') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'" />
				<div :class="isUrl('settings') ? 'text-white' : 'text-indigo-300 group-hover:text-white'">Settings</div>
			</inertia-link>
		</div>
	</div>
</template>

<script>
import Icon from '@/Shared/Icon'

export default {
	components: {
		Icon,
	},
	data() {
		return {
			forAdmin: false,
			forApi: false,
			forAdminTools: false,
		}
	},
  	methods: {		
		isUrl(url, reSources = true) {
			console.log("url: " + route().current() )
			if(reSources) {
				if( route().current( url + '.index') || route().current( url + '.create') || route().current( url + '.edit') || route().current( url + '.show') ) {
					return true;
				} else {
					return false;
				}
			} else {
				if( route().current( url )) {
					return true;
				} else {
					return false;
				}
			}
		},
		toggle() {
			this.forAdmin = !this.forAdmin;			
			this.forApi = false;
			this.forAdminTools = false;
		},
		allToggleOff() {
			this.forAdmin = false; this.forApi = false; this.forAdminTools = false;
		},
		apiToggle() {
			this.forApi = !this.forApi;  this.forAdminTools = false; this.forAdmin = false;
		},
		adminToolsToggle() {
			this.forAdminTools = ! this.forAdminTools; this.forApi = false; this.forAdmin = false;
		},
  	},
}
</script>
