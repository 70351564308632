<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route(urlName + '.index')">{{ pageName }}</inertia-link>
      <span class="text-indigo-400 font-medium">/</span> Create
    </h1>
    <div class="bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="store" autocomplete="off">
        <div class="p-8">
          <form-row label="Short Version">
            <text-input-required v-model="form.short_version" :error="form.errors.short_version" />
          </form-row>
          <form-row label="Long Version">
            <textarea-input v-model="form.long_version" :error="form.errors.long_version" />
          </form-row>
        </div>
        <div class="px-8 py-4 bg-gray-50 border-t border-gray-100 flex justify-end items-center">
          <loading-button :loading="form.processing" class="btn-indigo" type="submit">Create {{ pageName }}</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextInputRequired from '@/Shared/TextInputRequired'
import TextareaInput from '@/Shared/TextareaInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import FormRow from '@/Shared/FormRow'

export default {
  metaInfo() {
    return {
      title: `Create ${this.pageName}`,
    }
  },
  components: {
    LoadingButton,
    SelectInput,
    TextInput,
    TextInputRequired,
    FormRow,
    TextareaInput,
  },
  layout: Layout,
  props: {
    pageName: String,
    urlName: String,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        short_version: null,
        long_version: null,
      }),
    }
  },
  methods: {
    store() {
      this.form.post(this.route( this.urlName + '.store'))
    },
  },
}
</script>
