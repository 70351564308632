<template>
    <div>
        <div class="mb-4 grid grid-flow-col grid-cols-3 gap-2">
            <DatePicker 
                aria-label="date picker"
                valueType="YYYY-MM-DD"
                v-model="section.note_date">
            </DatePicker>
            <textarea-input v-model="section.content"></textarea-input>
            <div>
                <button type="button" class="btn-indigo ml-auto text-sm" v-on:click="$emit('remove')">Remove</button>
            </div>
        </div>
    </div>
</template>

<script>
import TextareaInput from '@/Shared/TextareaInput'
import DatePicker from 'vue2-datepicker';

export default {
    props: ['section'],
    components: {
        DatePicker,
        TextareaInput,
    },
    data: function() {
        return {
        }
    },
};
</script>