<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">Reports</h1>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'

export default {
  metaInfo: { title: 'Reports' },
  layout: Layout,
}
</script>
