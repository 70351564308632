<template>
<div :class="result.deleted_at !== null ? 'bg-red-100' : rowColor(result.marking)" class="border-t grid grid-cols-12 gap-2 hover:bg-yellow-100 focus-within:bg-gray-100 text-xs"> 
    <div class="px-3 py-4" :class="$page.props.auth.user.perm.records.edit ? ' col-span-2 ' : ' col-span-4 '" >
        <inertia-link class="items-center" :href="urlResult" tabindex="-1">
            <div v-if="result.title">
            {{ result.title }}
            </div>
        </inertia-link>
    </div>
    <div class="px-2 py-4">
        <inertia-link class="items-center" :href="urlResult" tabindex="-1">
            <div v-if="result.marking">
            <span v-for="(value, key) in result.marking" v-bind:key="key">{{ key }}</span>
            </div>
            <div v-else>
            <span>Not tracking</span>
            </div>
        </inertia-link>
    </div>
    <div class="px-2 py-4">
        <inertia-link class="items-center" :href="urlResult" tabindex="-1">
            <div v-if="result.indicator" class="break-all">
            {{ result.indicator.short_version | subStr(' ') }}
            </div>
        </inertia-link>
    </div>
    <div class="px-2 py-4">
        <inertia-link class="items-center" :href="urlResult" tabindex="-1">
            <div v-if="result.countries">
                <ul class="list-inside list-disc">
                    <li class="" v-for="(value, key) in result.countries" v-bind:key="key">{{ value.short_name }}</li>
                </ul>
            </div>
        </inertia-link>
    </div>
    <div class="px-2 py-4 justify-self-center">
        <inertia-link class="items-center" :href="urlResult" tabindex="-1">
            <div v-if="result.year_result_met">
            {{ result.year_result_met.join(', ') }}
            </div>
        </inertia-link>
    </div>
    <div class="px-2 py-4 justify-self-center">
        <inertia-link class="items-center" :href="urlResult" tabindex="-1">
            <div v-if="result.reporting_period">
                {{ result.reporting_period }}
            </div>
        </inertia-link>
    </div>
    <div class="px-2 py-4">
    <inertia-link class="items-center" :href="urlResult" tabindex="-1">
        <div v-if="result.activities">
            <ul class="list-inside list-disc">
                <li class="" v-for="(value, key) in result.activities" v-bind:key="key">{{ value.activity_code }}</li>
            </ul>
        </div>
    </inertia-link>
    </div>
    <div class="px-2 py-4">
    <inertia-link class="items-center" :href="urlResult" tabindex="-1">
        <div v-if="result.activities">
        <ul class="list-inside list-disc">
            <li class="" v-for="(value, key) in result.activities" v-bind:key="key">
                <span v-if="value.leadcontact">
                {{ value.leadcontact.first_name }} {{ value.leadcontact.last_name }}
                </span>
                <span v-else>N/A</span>
            </li>
        </ul>
        </div>
    </inertia-link>
    </div>
    <div class="px-2 py-4 col-span-2" v-if="$page.props.auth.user.perm.records.edit">
    <inertia-link class="items-center" :href="urlResult" tabindex="-1">
        <div v-if="result.note">
            {{ stringLimiter(result.note.content) }}
        </div>
    </inertia-link>            
    </div>
    <div class="px-2 py-4" v-if="$page.props.auth.user.perm.records.edit">
    <inertia-link class="items-center" :href="urlResult" tabindex="-1">
        <div v-if="result.note">
            {{ result.note.note_date }}
        </div>
    </inertia-link>
    </div>
</div>
</template>

<script>
export default {
    props: ['result', 'urlResult'],
    components: {        
    },
    methods: {
        stringLimiter(text) {
            if(typeof text === "string") {
                return text.length > 120 ? text.slice(0, 120) + '...' : text;
            } else {
                return text;
            }
        },
        rowColor(state) {
            const marking = Object.keys(state);
            if (marking[0] == 'Tracking') {
                return 'bg-blue-100';
            } else if (marking[0] == 'Not tracking') {
                return 'bg-gray-100';
            } else if (marking[0] == 'Result met') {
                return 'bg-green-100';
            } else if (marking[0] == 'Pre-tracking') {
                return 'bg-amber-100';
            } else if (marking[0] == 'Needs evidence') {
                return 'bg-red-100';
            } else {
                return 'bg-black-100';
            } 
        },
    },
    mounted() {
    },
    filters: {
  	    subStr: function(str, delim) {
        const idx = str.indexOf(delim);
    	    return str.substring(0, idx);
    }
  },
};
</script>