<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">Dashboard</h1>
    <!--<h2>{{$page.props.auth.user.perm.userID}}</h2>-->
    <div class="lg:grid lg:grid-flow-row">
      <HighChartPie class="m-8 shadow-xl"
                    v-if="$page.props.auth.user.perm.records.non_usaid_view" 
                    :title=this.pie_chart_title
                    :label=this.pie_chart_label
                    :data=this.pie_chart_data
                    :total=this.pie_chart_total></HighChartPie>
      <HighChartStackTarget class="m-8 shadow-xl"
                    v-for="item in this.stack_chart_data.items" :key="item.key"
                    :title=item.key
                    :series=item.series
                    :label=stack_chart_data.label
                    :categories=item.categories></HighChartStackTarget>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'

import HighChartBasic from '@/Shared/HighChartBasic'
import HighChartPie from '@/Shared/HighChartPie'
import HighChartStack from '@/Shared/HighChartStack'
import HighChartStackTarget from '@/Shared/HighChartStackTarget'

import { EventBus } from "@/event-bus.js";

export default {
  metaInfo: { title: 'D4I Results Dashboard' },
  props: {
    pie_chart_title: String,
    pie_chart_label: String,
    pie_chart_data: Array,
    pie_chart_total: Number,
    stack_chart_data: Object,
  },
  components: {
    HighChartBasic,
    HighChartPie,
    HighChartStack,
    HighChartStackTarget,
  },
  data() {
    return {
    }
  },
  layout: Layout,
  mounted() {
    // Use event bus to avoid page reload
    EventBus.$on('chartClicked', url => {
      this.$inertia.get(url)
    });
  },
}
</script>
