<template>
    <div>
        <DataGridReadOnlyFileFieldRow :section="section" v-for="(section, index) in sections" v-bind:key="index" :is_note_file="is_note_file"></DataGridReadOnlyFileFieldRow>
        <!-- p class="p-4 text-xs text-blue-400">DEBUG: {{ sections }}</p -->
    </div>
</template>

<script>
import DataGridReadOnlyFileFieldRow from '@/Shared/DataGridReadOnlyFileFieldRow'

export default {
    props: ['sections', 'is_note_file'],
    components: {
        DataGridReadOnlyFileFieldRow,
    },
    data: function() {
        return {
        }
    },
    methods: {
    }
};
</script>