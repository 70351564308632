<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route(urlName+ '.index')">{{ pageName }}</inertia-link>
      <span class="text-indigo-400 font-medium">/</span>
      {{ form.short_version }}
    </h1>
    <trashed-message v-if="varObj.deleted_at" class="mb-6" @restore="restore">
      This {{ pageName.toLowerCase() }} has been deleted.
    </trashed-message>
    <div class="bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update" autocomplete="off">
        <fieldset :disabled="varObj.deleted_at"  class="p-8">
          <form-row label="Short Version">
            <text-input-required v-model="form.short_version" :error="form.errors.short_version" />
          </form-row>
          <form-row label="Long Version">
            <textarea-input v-model="form.long_version" :error="form.errors.long_version" />
          </form-row>
          <form-row label="Target">
            <text-input v-model="form.target" :error="form.errors.target" type="number" class="w-full lg:w-1/4" min="0" />
          </form-row>
        </fieldset>
        <div v-if="!varObj.deleted_at" class="px-8 py-4 bg-gray-50 border-t border-gray-100 flex items-center">
          <button class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy">Delete {{ pageName }}</button>
          <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit">Update {{ pageName }}</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextInputRequired from '@/Shared/TextInputRequired'
import TextareaInput from '@/Shared/TextareaInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'
import FormRow from '@/Shared/FormRow'

export default {
  metaInfo() {
    return {
      title: `Edit ${this.pageName}`,
    }
  },
  components: {
    LoadingButton,
    TextInput,
    TextInputRequired,
    TextareaInput,
    TrashedMessage,
    FormRow,
  },
  layout: Layout,
  props: {
    varObj: Object,
    pageName: String,
    urlName: String,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        short_version: this.varObj.short_version,
        long_version: this.varObj.long_version,
        target: this.varObj.target,
      }),
    }
  },
  methods: {
    update() {
      this.form.put(this.route(this.urlName + '.update', this.varObj.id))
    },
    destroy() {
      if (confirm('Are you sure you want to delete this ' + this.pageName.toLowerCase() + '?')) {
        this.$inertia.delete(this.route(this.urlName + '.destroy', this.varObj.id))
      }
    },
    restore() {
      if (confirm('Are you sure you want to restore this ' + this.pageName.toLowerCase() + '?')) {
        this.$inertia.put(this.route(this.urlName + '.restore', this.varObj.id))
      }
    },
  },
}
</script>
