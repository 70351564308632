<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('resultareas')">Result Areas</inertia-link>
      <span class="text-indigo-400 font-medium">/</span>
      {{ form.short_version }}
    </h1>
    <trashed-message v-if="resultarea.deleted_at" class="mb-6" @restore="restore">
      This result area has been deleted.
    </trashed-message>
    <div class="bg-white rounded-md shadow overflow-hidden">
      <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
        <p class="pr-6 pb-4 w-full lg:w-1/2 font-medium">Short Version:</p>
        <p class="pr-6 pb-8 w-full lg:w-1/2">{{ resultarea.short_version }}</p>
        <p class="pr-6 pb-4 w-full lg:w-1/2 font-medium">Long Version:</p>
        <p class="pr-6 pb-8 w-full lg:w-1/2">{{ resultarea.long_version }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  metaInfo() {
    return {
    }
  },
  components: {
    TrashedMessage,
  },
  layout: Layout,
  props: {
    resultarea: Object,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        short_version: this.resultarea.short_version,
        long_version: this.resultarea.long_version,
      }),
    }
  },
  methods: {
  },
}
</script>
